import * as React from "react";
import Layout from "../components/layout";
import { SEO, ThreeImageSection } from "../components";
import styled, { keyframes } from "styled-components";
import { PrimaryButton } from "../components/buttons";
import { Parallax } from "react-parallax";
import { navigate } from "gatsby";
import { DesktopView } from "../components/layout-helpers";
import { MobileView } from "../components/layout-helpers";
import CertificationBanner from "../components/CertificationBanner";
import Helmet from "react-helmet";
import background from "../images/home_top.jpg";
import logo from "../images/logo.png";
import roofing from "../images/roofing.jpg";
import concrete from "../images/home2.jpg";
import framing from "../images/framing.jpg";
import sparks from "../images/sparks.jpg";
import handyman from "../images/handyman.png"
import { breakpoints } from "../constants";

const tileContent = {
  top: {
    titleTop: "Commercial & Residential",
    titleBottom: "Concrete, Masonry, Framing and Roofing",
    subTitle: "No job is too big or too small",
    buttonText: "Free Estimate",
    to: "/estimate",
  },
  highlights: {
    title: "We offer a wide range of concrete services",
    imageOptions: [
      {
        src: concrete,
        subTitle: "Concrete",
        to: "/services/concrete",
        buttonText: 'View Concrete Services'
      },
      {
        src: framing,
        subTitle: "Framing",
        to: "/services/framing",
        buttonText: 'View Framing Services'
      },
      {
        src: roofing,
        subTitle: "Roofing",
        to: "/services/roofing",
        buttonText: 'View Roofing Services'
      },
      {
        src: handyman,
        subTitle: "Handyman Services",
        to: "/services/handyman-services",
        buttonText: 'View Handyman Services',
        customStyles: `
          object-fit: contain;
          transform: scale(1.15) translateY(-10px);
        `
      },
    ],
  },
};

const seoContent = {
  siteUrl: "https://hconcoinc.com",
  title: "Home",
  keywords: [`concrete`, `construction`, `hconco`, `nashville`],
  description:
    "Residential & commercial concrete construction in Nashville, TN. We work on all varieties of projects from major commercial projects such as tilt up concrete to residential jobs like sidewalks and curbs.",
};

const fade = keyframes`
  from {
    margin-left: -0.5rem;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const Top = styled.div`
  min-height: 700px;
  max-height: 700px;
  width: 100%;
  background-image: url(${background});
  background-size: cover;
`;

const FlexContainerColumnTop = styled(FlexContainerColumn)`
  height: 600px;
`;

const FlexItem = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
`;

const Logo = styled.img`
  height: 10rem;
  animation: ${fade} 0.7s linear;
`;

const TitleText = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.textWhite};
  font-size: 2.5rem;
  text-shadow: 1px 5px 20px rgba(0, 0, 0, 0.75);
  animation: ${fade} 1s linear;
`;

const SubtitleText = styled.div`
  color: ${(props) => props.theme.textWhite};
  font-size: 1.4rem;
  text-shadow: 1px 5px 20px rgba(0, 0, 0, 0.75);
  padding-bottom: 2rem;
  animation: ${fade} 1s linear;
`;

const Section = styled.div`
  width: 80%;
  min-height: 500px;
  margin: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 70%;
  }
`;

const TopSection = () => (
  <Top>
    <FlexContainerColumnTop>
      <FlexItem>
        <Logo src={logo} alt={"Logo"} />
      </FlexItem>
      <FlexItem>
        <TitleText>
          <div>{tileContent.top.titleTop}</div>
          <div>{tileContent.top.titleBottom}</div>
        </TitleText>
      </FlexItem>
      <FlexItem>
        <SubtitleText>{tileContent.top.subTitle}</SubtitleText>
      </FlexItem>
      <FlexItem>
        <PrimaryButton onClick={() => navigate(tileContent.top.to)}>
          {tileContent.top.buttonText}
        </PrimaryButton>
      </FlexItem>
    </FlexContainerColumnTop>
  </Top>
);

const Mobile = () => (
  <>
    <TopSection />
    <CertificationBanner />
    <Section>
      <ThreeImageSection
        title={tileContent.highlights.title}
        imageOptions={tileContent.highlights.imageOptions}
      />
    </Section>
    <StyledParallax
      blur={0}
      bgImage={sparks}
      bgImageAlt="Sparks"
      strength={200}
    >
      <div style={{ height: "250px" }} />
    </StyledParallax>
  </>
);

const Desktop_Section1 = styled.div`
  background-color: #fff;
  color: #000;
  width: 100%;
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
`;


const Desktop = () => (
  <>
    <TopSection />
    <CertificationBanner />
    <Desktop_Section1>
      <ThreeImageSection
        title={tileContent.highlights.title}
        imageOptions={tileContent.highlights.imageOptions}
      />
    </Desktop_Section1>
    <StyledParallax
      blur={0}
      bgImage={sparks}
      bgImageAlt="Sparks"
      strength={400}
    >
      <div style={{ height: "250px" }} />
    </StyledParallax>
  </>
);

const IndexPage = () => (
  <Layout>
    <SEO
      title={seoContent.title}
      keywords={seoContent.keywords}
      description={seoContent.description}
      siteUrl={seoContent.siteUrl}
    />
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://hconcoinc.com",
          "contactPoint": [
            { "@type": "ContactPoint",
               "name": "H-Con-Co Inc",
               "description": "Residential & commercial concrete construction in Nashville, TN. We work on all varieties of projects from major commercial projects such as tilt up concrete to residential jobs like sidewalks and curbs.",
              "telephone": "+1-615-440-0419",
              "contactType": "customer service",
              "email": "hconcoinc@gmail.com"
            }
          ]
        }
    `}</script>
    </Helmet>
    <FlexContainerColumn>
      <DesktopView>
        <Desktop />
      </DesktopView>
      <MobileView>
        <Mobile />
      </MobileView>
    </FlexContainerColumn>
  </Layout>
);

export default IndexPage;
